<!--客户管理-我的设备-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
      <el-form :inline='true' :model='filter' size="mini">
        <el-form-item>
          <el-input v-model.trim='filter.mn' placeholder='编码' @keyup.native.enter.stop="getData">
            <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select @change="getData" v-model.trim='filter.transfer_type' placeholder="转发区域" clearable>
            <el-option
              v-for="item in transfer"
              :key="item.K"
              :label="item.V"
              :value="item.K">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable/>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type='primary' size='mini' @click='getData'>查询</el-button> -->
          <el-button type='primary' @click='openExportSizeInput'>导出</el-button>
          <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading'
                @selection-change='selsChange' :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='selection'/>
        <el-table-column type='index' label='#' align='center' width='55'/>
        <el-table-column prop='MN' label='设备编码' align='center' width='220' header-align='center'/>
        <el-table-column prop='owner_name' label='所属单位' show-overflow-tooltip width='200' header-align='center'/>
        <el-table-column prop='LocaleId' label='安装地址' show-overflow-tooltip width='260' header-align='center'
                         :formatter="localeFormatter"/>
        <el-table-column prop='Brand' label='品牌' align='center' header-align='center'/>
        <el-table-column prop='Model' label='型号' :formatter="modelFormatter" align='center' header-align='center'/>
        <el-table-column prop='Subtype' sortable label='设备类型' :formatter="subTypeFormatter" align='center'
                         header-align='center'/>
        <el-table-column prop='Sim' label='SIM卡' align='center' header-align='center'/>
        <el-table-column prop='TransferType' label='转发区域' :formatter="transferFormatter" align='center'
                         header-align='center'/>
        <el-table-column prop='Ip' label='IP' width="130" align='center' header-align='center'/>
        <el-table-column prop='Number' label='重复次数' align='center' header-align='center'>
        </el-table-column>
        <el-table-column label='操作' align='center' header-align='center' fixed="right" width="140px">
          <template slot-scope='scope'>
            <i title="查看每分钟数据" @click="handleShowIntime(scope.row)" class="opt el-icon-notebook-2"/>
            <i title="设备绑定记录" class="opt el-icon-time" @click="handleRecord(scope.row)"></i>
            <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit'/>
            <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete'/>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 设备绑定记录 beginning -->
    <el-dialog title='设备绑定记录' :visible.sync='recordVisible' width="800px">
      <el-table :data='recordlist.content' size='mini' border highlight-current-row :max-height='clientHeight - 40'
                style='width: 100%'>
        <el-table-column prop='DeviceMn' label='设备编号' align='center' header-align='center'/>
        <el-table-column prop='BeginAt' :formatter="dateFormat" label='绑定时间' align='center' header-align='center'/>
        <el-table-column prop='EndAt' :formatter="dateFormat" label='移机时间' align='center' header-align='center'/>
      </el-table>
      <div>
        <el-pagination small background @current-change='handleRecordChange' :current-page.sync='filterRecord.page'
                       layout='total, prev, pager, next, jumper' :total='recordlist.total'
                       style='display:inline-block;margin-left:15px'>
        </el-pagination>
      </div>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='recordVisible=false'>关闭</el-button>
      </div>
    </el-dialog>
    <!-- 设备绑定记录 end -->

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-button type='danger' size='mini' @click='bulkRemove' :disabled='sels.length===0'>批量删除</el-button>
      <el-button type='primary' size='mini' @click='handleDeviceLowering' :disabled='sels.length===0'>设备下发
      </el-button>
      <el-pagination small background @size-change='handleSizeChange' @current-change='handleCurrentChange'
                     :current-page.sync='filter.page' :page-sizes='filter.pageSizes'
                     layout='total, sizes, prev, pager, next, jumper' :total='datalist.total'
                     style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' width="35%" @close='handlerClose'>
      <div v-if="dlg.state===3" style="text-align:center">
        <el-cascader
          placeholder="下发单位关键字"
          size="mini"
          v-model.trim='formData.Owner'
          @change='hasChange'
          :options="customerTree"
          :props="customerProps"
          clearable
          filterable/>
      </div>
      <el-form v-else :model='formData' label-width='130px' :rules='formRule' ref='form' size='mini'>
        <el-form-item prop='MN' label='设备编码'>
          <el-input @change='hasChange' v-model.trim='formData.MN'/>
        </el-form-item>
        <el-form-item prop='PW' label='设备密码'>
          <el-input @change='hasChange' v-model.trim='formData.PW'/>
        </el-form-item>
        <el-form-item label='所属单位' prop="Owner">
          <el-cascader
            placeholder="关键字"
            v-model.trim='formData.Owner'
            @change='handleOwnerChange'
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable/>
        </el-form-item>
        <el-form-item label='监测点' v-if="formData.Owner.length > 0" prop="LocaleId">
          <el-select
            @change="hasChange"
            v-model.trim='formData.LocaleId'
            clearable
            filterable>
            <el-option
              v-for="item in localOption"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
              :disabled="item.Disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='监测方式'>
          <el-select disabled v-model="currentLocale.SendMode" placeholder="未选择监测方式">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='类型-型号' prop="cascade">
          <el-cascader placeholder="请选择"
                       v-model="cascade"
                       :props="deviceProps"
                       :options="alterDeviceType(deviceType)"
                       @change="hasChange"/>
        </el-form-item>
        <el-form-item prop='Brand' label='品牌'>
          <el-input @change='hasChange' v-model.trim='formData.Brand'/>
        </el-form-item>
        <el-form-item prop='TransferType' label='转发区域'>
          <el-select @change="hasChange" v-model.trim='formData.TransferType'>
            <el-option
              v-for="item in transfer"
              :key="item.K"
              :label="item.V"
              :value="item.K">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop='Sim' label='SIM卡'>
          <el-input @change='hasChange' v-model.trim='formData.Sim'/>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click='dlg.state===3?bulkDeviceLowering():handleSubmit()'>提交
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="eldg.title"
      :visible.sync="eldg.visible"
      width="85%"
      @close="close"
    >
      <span slot="title"><strong>{{ owner }}</strong></span>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-date-picker
            @change="handleDateSel"
            v-model="selDate"
            type="date"
            value-format="timestamp"
            placeholder="日期选择"
          />
        </el-form-item>
      </el-form>
      <template>
        <el-table
          :data="intimeData.content"
          size="mini"
          border
          highlight-current-row
          v-loading="inloading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="55"
          />
          <el-table-column
            prop="acquit_at"
            :formatter="dateFormat"
            label="采集时间"
            width="140"
            align="center"
            header-align="center"
          />

          <template v-if="devicetyp === 2">
            <el-table-column
              prop="emissions_conc"
              label="油烟浓度(mg/m³)"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="granule_conc"
              label="颗粒物浓度(mg/m³)"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="hydrocarbon_conc"
              label="非甲烷总烃浓度(mg/m³)"
              align="center"
              header-align="center"
            />
          </template>
          <template v-else>
            <el-table-column
              label="风机状态"
              align="center"
              header-align="center"
            >
              <template slot-scope='scope'>
                <span>{{ scope.row.fan_status == 1 ? '开' : '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="净化器状态"
              align="center"
              header-align="center"
            >
              <template slot-scope='scope'>
                <span>{{ scope.row.filter_status == 1 ? '开' : '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="poweroff"
              label="电源关闭指令"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc1"
              label="电源1故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv1"
              label="电源1电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc2"
              label="电源2故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv2"
              label="电源2电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc3"
              label="电源3故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv3"
              label="电源3电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc4"
              label="电源4故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv4"
              label="电源4电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="flag"
              label="设备状态"
              width="70"
              align="center"
              header-align="center"
            />
          </template>
        </el-table>
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'intimeSize')"
          @current-change="(v) => handlePageChange(v, 'intimePage')"
          :current-page.sync="filter.intimePage"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="intimeData.total"
          style="display: inline-block; margin-left: 15px"
        />
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  deviceTreeFilter,
  referToMap,
  getUserInfo,
  export_json,
  toIntArray,
  dataFormatter,
  join,
  ifNull,
  getPname,
  dateFormater, ownerJoin,ownerFilter
} from '@/util/index'

export default {
  name: 'Device',
  components: {},
  data() {
    return {
      options: [{
        value: 1,
        label: '合并传输'
      }, {
        value: 2,
        label: '独立传输'
      }, {
        value: 3,
        label: '两路传输'
      }],
      filterRecord: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20
      },
      device_id: '',
      recordlist: {},
      recordVisible: false, // 是否显示设备绑定记录弹窗
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      deviceType: [],
      locales: [],
      localOption: [],
      transfer: [],
      referMap: {},
      transferMap: [],
      cascade: [],
      curPid: '',
      userInfo: null,
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      owner: '',
      selDate: null,
      eldg: {
        visible: false,
        title: ''
      },
      inloading: false,
      intimeData: {},
      devicetyp: 2,
      filter: {
        page: 1,
        size: 20,
        intimePage: 1,
        intimeSize: 20,
        pageSizes: [20, 30, 50]
      },
      formData: {
        LocaleId: '',
        Owner: []
      },
      formRule: {
        MN: [
          {required: true, validator: this.existsValidator, trigger: 'blur'}
        ],
        LocaleId: [
          {required: true, message: '监测点不可为空', trigger: 'blur'}
        ]
      },
      currentLocale: {},
    }
  },
  computed: {
    ...mapState({
      orgProps: state => Object.assign({}, state.props, {label: 'Org'}),
      deviceProps: state => Object.assign({}, state.props, {label: 'Name'}),
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'user']),
    transformSendMode(SendMode) {
      if (SendMode) {
        return SendMode === 1 ? '合并传输' : '独立传输'
      } else {
        return '未选择监测方式'
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    'formData.LocaleId': {
      immediate: false,
      handler(val) {
        if (val && this.localOption.length > 0) {
          const t = this.localOption.find(e => e.Id === val)
          if (t) {
            this.$post('admin/getDevicesOfLocale', {LocaleId: val}).then(res => {
              this.currentLocale = JSON.parse(JSON.stringify(t))
              this.currentLocale.Devices = Object.assign([], res)
            })
              .catch(e => {
                console.log(e)
              })
          }
        }
      }
    }
  },
  methods: {
    init() {
      this.loading = true

      this.userInfo = getUserInfo().Info
      console.log('userInfo',this.userInfo)
      if (this.userInfo !== null) {
        // + this.userInfo['id']
        this.curPid = `${ifNull(this.userInfo['pid']) }`
        console.log('init里面的',this.curPid)
      }
      this.$get('admin/listDeviceLocaleAlter').then(res => {
        if (res) {
          this.locales = res
        }
      })
      this.$post('admin/listReferParam', {code: 'TRANSFER_TYPE'}).then(res => {
        this.transfer = res
        this.transferMap = referToMap(res)
      })
      this.$post('admin/listDevice').then(res => {
        this.referMap = referToMap(res.list, 'Id', 'Name')
        this.deviceType = deviceTreeFilter(res.list)
      })
      this.getData()
    },
    alterDeviceType(data) {
      const deviceType = Object.assign([], data)
      return deviceType
    },
    getDeviceTypName(refer) {
      switch (refer[0]) {
        case '1':
          return '风机设备'
        case '2':
          return '净化设备'
        case '3':
          return '监测仪设备'
        default:
          return '未找到类型'
      }
    },
    subTypeFormatter(r, c) {
      return this.referMap['d' + r[c.property]]
    },
    modelFormatter(r, c) {
      return this.referMap['c' + r[c.property]]
    },
    transferFormatter(r, c) {
      return this.transferMap[r[c.property]]
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    customerFormatter(r, c, v) {
      return getPname(this.customerTree, v, 'Org').join('/')
    },
    hasChange() {
      this.changeState = true
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(exportNum) {
      const info = []
      const para = {
        page: 1,
        size: exportNum,
        isDownload: true
      }

      if (this.filter.mn) {
        para['mn'] = this.filter.mn
      }
      if (this.filter.transfer_type) {
        para['transferType'] = this.filter.transfer_type
      }
      if (this.filter.owner) {
        let owner = join(this.filter.owner)
        if (this.userInfo !== null) {
          owner = `${ifNull(this.userInfo['pid']) + this.userInfo['id']}${owner}`
        }
        para['Owner'] = owner
      }
      //原接口为listAllDevice，未作分页
      this.$post('admin/listDevice', para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    handleRecordChange(page) {
      this.filterRecord.page = page
      this.filterRecord.lpage = page
      this.getRecordList()
    },
    handleRecord(row) {
      this.device_id = row.Id
      this.getRecordList()
    },
    getRecordList() {
      const filterRecord = JSON.parse(JSON.stringify(this.filterRecord))
      filterRecord.StartAt = filterRecord.size * (filterRecord.page - 1)
      filterRecord.Size = filterRecord.size
      filterRecord['Param'] = {device_id: this.device_id}
      this.$post('admin/listLocaleDeviceRecord', filterRecord).then(res => {
        this.recordlist = res
        this.recordlist = res
        this.recordVisible = true
      }).catch(() => {
      })
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    handleOwnerChange(v) {
      this.currentLocale = {}
      this.formData.LocaleId = null
      let owner = join(v)
      if (this.curPid.endsWith('/')) {
        this.curPid = this.curPid.slice(0, -1);
      }
      if (this.curPid !== '') {
        owner = this.curPid + owner
      }
      this.localOption = []
      this.locales.forEach(e => {
        if (e.Owner === owner) {
          console.log('e',e)
          this.localOption.push(e)
        }
      })
      this.changeState = true
    },
    localeFormatter(r, c, v) {
      // console.log(r)
      // console.log(c)
      // console.log(v)
      // for (let i = 0, len = this.locale.length; i < len; i++) {
      //   if (this.locale[i].Id === v) {
      //     return this.locale[i].Name + '/' + this.locale[i].Addr
      //   }
      // }
    },
    existsValidator(rule, mn, callback) {
      if (mn === undefined) {
        callback(new Error(`编号不可为空`))
      } else {
        if (this.dlg.state === 1 || this.formData.OldMN !== this.formData.MN) {
          this.$get('admin/checkDevice', {mn}).then((res) => {
            if (res) {
              callback(new Error(`编码已存在`))
            } else {
              callback()
            }
          })
        } else {
          callback()
        }
      }
    },
    getData: function () {
      this.loading = true
      var para = {StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size}
      para.Param = {}
      if (this.filter.mn) {
        para.Param['mn'] = {S: 4, V: this.filter.mn}
      }
      if (this.filter.transfer_type) {
        para.Param['transfer_type'] = {S: 0, V: this.filter.transfer_type.toString()}
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        let owner = ownerJoin(this.filter.owner, this.userInfo)
        para.Param['Owner'] = {V: owner}
      }
      this.$post('admin/listDevice', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleTypChange(value) {
      this.changeState = true
      this.formData.Typ = value[0]
      this.formData.Model = value[1]
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.mn = null
      }
      this.$get('admin/listDeviceLocaleAlter').then(res => {
        if (res) {
          this.locales = res
        }
      })
      this.getData()
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.dlg.state = 1
      this.dlg.visible = true
      this.cascade = []
      this.formData = {LocaleId: [], PW: '123456', Owner: []}
    },
    handleEdit: function (index, row) {
      this.dlg.title = '编辑'
      this.dlg.state = 2
      this.dlg.visible = true
      this.index = index
      if (row.Typ === 0) {
        this.cascade = null
      } else if (row.Model) {
        this.cascade = [row.Subtype.toString(), row.Model.toString()]
      }

      let localeId
      for (let i = 0, len = this.locales.length; i < len; i++) {
        if (this.locales[i].Id === row.LocaleId) {
          localeId = this.locales[i].Id
          break
        }
      }
      this.$nextTick(() => {
        this.formData = Object.assign({}, row, {OldMN: row.MN,  LocaleId: localeId})
        this.formData.TransferType = row.TransferType === 0 ? null : row.TransferType
        this.localOption = []
        this.locales.forEach(e => {
          if (e.Owner === this.formData.Owner) {
            this.localOption.push(e)
          }
        })
        let userInfo = getUserInfo().Info
        this.formData.Owner = ownerFilter(this.formData.Owner, userInfo)
      })
    },
    handleDateSel(v) {
      if (v !== null) {
        this.getIntimeData()
      }
    },
    handleShowIntime(r) {
      this.mn = r.MN
      this.devicetyp = r.Typ
      this.owner = getPname(this.customerTree, r.Owner, 'Org').join('/')
      this.owner += '-分钟采集数据'
      this.getIntimeData()
      this.eldg.visible = true
    },
    getIntimeData() {
      this.inloading = true
      this.$post('admin/listIntimeData', {
        StartAt: (this.filter.intimePage - 1) * this.filter.intimeSize,
        Size: this.filter.intimeSize,
        mn: this.mn,
        typ: this.devicetyp,
        at: this.selDate / 1000
      }).then((res) => {
        this.inloading = false
        this.intimeData = res
        if (
          res &&
          res.content &&
          res.content.length !== 0 &&
          this.selDate === null
        ) {
          this.selDate = new Date(
            new Date(res.content[0]['acquit_at'] * 1000).setHours(0, 0, 0, 0)
          ).getTime()
        }
      })
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getIntimeData()
    },
    handleDeviceLowering() {
      this.dlg.title = '设备下发'
      this.dlg.visible = true
      this.dlg.state = 3
    },
    bulkDeviceLowering() {
      if (this.formData.Owner) {
        this.$post('admin/bulkDeviceLowering', {
          owner: join(this.formData.Owner),
          ids: this.sels.map(item => item.Id)
        }).then((res) => {
          this.getData()
        }).catch(() => {
        })
      }
      this.dlg.visible = false
    },
    // 批量删除
    bulkRemove: function () {
      const ids = this.sels.map(item => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post('admin/bulkRemoveDevice', {ids: ids}).then((res) => {
          this.getData()
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const para = {id: row.Id}
        this.$get('admin/delDevice', para).then(() => {
          this.datalist.content.splice(index, 1)
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    handleSubmit() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const para = Object.assign({}, this.formData)
            para.Owner = join(this.formData.Owner)
            para.Typ = 2
            para.Subtype = parseInt(this.cascade[0])
            para.Model = parseInt(this.cascade[1])
            // const t = this.currentLocale.Devices.find(e => e.Model.toString() === this.cascade[this.cascade.length - 1][1])
            // para.Id = t ? t.Id + '|' + para.Id : para.Id
            this.$post('admin/saveDevice', para).then(() => {
              this.dlg.visible = false

            }).catch((e) => {
              console.log(e)
            })
          }).catch((e) => {
            console.log(e)
          })
        }
      })
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh(false)
      }
      this.formData = {
        LocaleId: '',
        Owner: []
      }
      this.currentLocale = {}
      this.localOption = []
      this.cascade = []
    },
    close() {
      this.selDate = null
      this.filter.intimePage = 1
    }
  }
}
</script>
<style lang="scss" scoped>
.el-icon-download {
  color: #666666;
}

.el-icon-time {
  color: #409EFF;
}
</style>
